import React from 'react';
import ImageText from './ImageText';
import Collaboration1 from '../images/collaboration-1.svg';
import Collaboration2 from '../images/collaboration-2.svg';
import Collaboration3 from '../images/col-3.svg';
import Collaboration4 from '../images/collaboration-4.svg';

export default () => {
  return (
    <section id="channels" className="channels-list" style={{ padding: 0 }}>
      <ImageText
        image={Collaboration1}
        title="统一视角，实现线索高效流转"
        description="B2B企业在「营」「销」一体化的趋势下，需要有统一的组织&平台&流程，更重要的是要有统一的视角，统一市场和销售系统实现内容、策略和目标共享，使市场和销售作为统一的组织运行，而统一的销售漏斗管理更是市场销售协同的第一步，实现高效的线索分配，如线索阶段by属性&事件的双重判断；自定义线索分配规则；线索未跟进自动提醒等。 "
        imageSize={6}
        position="right"
        bgColor="yellow"
        showDemoBtn
      />
      <ImageText
        image={Collaboration2}
        title="统一客户画像，丰富营销场景"
        description="通过构建统一的数字化营销平台，打通市场和销售相关的业务数据，实现营销侧与销售侧的协同与赋能，实现整个转化路径中的完整闭环，以always on形式持续互动，根据客户行为进行自动化培育&精准营销，增强用户体验，完善整体流程，如沉睡客户激活；定向市场活动推广；高价值客户关系培育；成交客户复购预警/交叉销售推荐。"
        imageSize={6}
        bgColor="white"
        showDemoBtn
      />
      <ImageText
        image={Collaboration3}
        title="市场渠道效率分析"
        description="「营」「销」一体化的核心是营销&销售两端的数据打通，帮助市场部门及时洞察从MAL-MIL-MQL-SQL-OPP-CUS端到端的渠道效果追踪分析，如MQL转SQL/OPP/CUS分析；MQL退回及退回原因分析；不同渠道线索转SQL分析 ；市场业绩贡献分析；市场活动效果分析等。"
        imageSize={6}
        position="right"
        bgColor="yellow"
        showDemoBtn
      />
      <ImageText
        image={Collaboration4}
        title="企微集成,沉淀客户资源"
        description="一线销售得到市场支撑赋能，客户保留在企业数据平台内，员工离职不丢失。SDR可以在企微通讯内选择对应部门人员快速建群，帮助销售&代理商快速完成客户对接，在跟进记录内，实现跨部门日常支持沟通。同时市场配合销售，预设销售资料库内容以及分类标准，销售在所属团队的可见范围内自由搜索或者筛选想要的素材，分享转发。"
        imageSize={6}
        bgColor="white"
        showDemoBtn
      />
    </section>
  );
};
