import React from 'react';
import Banner2 from '../components/Banner2';
import CollaborationImageTextList from '../components/CollaborationImageTextList';
import Layout from '../components/Layout';
import CasesBanner from '../images/scenes-banner.svg';
import SceneSolutions from '../components/SceneSolutions';
import Pain10 from '../images/pain-10.svg';
import Pain11 from '../images/pain-11.svg';
import Pain12 from '../images/pain-12.svg';
import PainsAndNeeds from '../components/PainsAndNeeds';
import SceneSolutionsImg4 from '../images/scenes-solutions-4.png';

const Ptitle3 =
  '根据业务规则进行线索自动打分和线索智能分配，销售实时获得最新客户\n行为轨迹，有效辅助商机跟进，完成「营」「销」一体化协同';

const VALUE_DATA = [
  {
    id: 'value-1',
    title: '线索管理流程不清晰',
    main: '',
    description: '缺乏对MQL/SQL的定义和管理，导致市场销售协作效率低',
    iconId: Pain10,
  },
  {
    id: 'value-2',
    title: '分配线索效率低',
    main: '',
    description: '第一手高质量线索难以马上推给销售，销售也难以即时跟进',
    iconId: Pain11,
  },
  {
    id: 'value-3',
    title: '销售互动方式单一',
    main: '',
    description: 'SDR/销售跟进线索时缺乏个性化内容互动，展示信息方式单调',
    iconId: Pain12,
  },
];

export default () => (
  <Layout current="scenes" subCurrent="collaboration">
    <Banner2
      title="市场销售协同"
      Ptitle3={Ptitle3}
      backgroundImage={CasesBanner}
      showDemoBtn
      buttonText="体验场景DEMO"
    />
    <PainsAndNeeds data={VALUE_DATA} />
    <SceneSolutions src={SceneSolutionsImg4} />
    <CollaborationImageTextList />
  </Layout>
);
